<template>
  <div>
    <h3 class="page-title">بىۋاستە كۆرسىتىش</h3>
    <el-divider></el-divider>

    <!-- table-box -->
    <div class="rtl-right search-box">
      <el-row :gutter="10">
        <el-button type="success" plain icon="el-icon-refresh" @click="toRefresh()">يېڭىلاش</el-button>
      </el-row>
    </div>

    <div class="table-box">
      <el-table :data="tableData" border style="width: 100%" v-loading="loading">
        <el-table-column type="expand" label="# ">
          <template slot-scope="props">
            <el-table :data="props.row.goods" border style="width: 100%">
              <el-table-column prop="name" label="مەھسۇلات نامى"></el-table-column>
              <el-table-column prop="price" label="باھاسى">
                <template slot-scope="scope">
                  <span
                    style="direction: ltr;display: inherit;"
                  >{{(scope.row.price / 100).toFixed(2)}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="price2" label="باھاسى">
                <template slot-scope="scope">
                  <span
                    style="direction: ltr;display: inherit;"
                  >{{(scope.row.price2 / 100).toFixed(2)}}</span>
                </template>
              </el-table-column>
              <el-table-column label="باھا شەكلى">
                <template slot-scope="scope">
                  <label v-if="scope.row.price_type == 1">一口价</label>
                  <label v-else-if="scope.row.price_type == 2">价格区间</label>
                  <label v-else-if="scope.row.price_type == 3">显示折扣价</label>
                  <label v-else>未知</label>
                </template>
              </el-table-column>
              <el-table-column prop="url" label="ئادرېس"></el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="room_id" label="ID" width="100"></el-table-column>
        <el-table-column prop="name" label="ئىسمى"></el-table-column>
        <el-table-column prop="anchor_name" label="تارقاتقۇچى"></el-table-column>
        <el-table-column label="باشلىنىش ۋاقتى">
          <template slot-scope="scope">
            <span style="direction: ltr;display: inherit;">{{scope.row.start_time}}</span>
          </template>
        </el-table-column>
        <el-table-column label="ئاخىرلىشىش">
          <template slot-scope="scope">
            <span style="direction: ltr;display: inherit;">{{scope.row.end_time}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="live_status" label="ھالىتى">
          <!-- 直播间状态。101：直播中，102：未开始，103已结束，104禁播，105：暂停，106：异常，107：已过期 -->
          <template slot-scope="scope">
            <label v-if="scope.row.live_status == 101">直播中</label>
            <label v-else-if="scope.row.live_status == 102">未开始</label>
            <label v-else-if="scope.row.live_status == 103">已结束</label>
            <label v-else-if="scope.row.live_status == 104">禁播</label>
            <label v-else-if="scope.row.live_status == 105">暂停</label>
            <label v-else-if="scope.row.live_status == 106">异常</label>
            <label v-else-if="scope.row.live_status == 107">已过期</label>
            <label v-else>未知</label>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="current_page"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        layout="sizes,prev, pager, next,total,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .table-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #ebeef5;
  }
  .qrcode-img {
    width: 95%;
    height: 450px;
  }
  .el-date-editor {
    width: 100%;
  }
  .choise-tip {
    position: relative;
    top: 10px;
    right: 10px;
    color: #e6a23c;
  }
  .drawer-table-box {
    padding: 0 10px;
  }
  .cur-text {
    position: relative;
    top: 10px;
    color: #409eff;
  }
  .search-box {
    .el-select,
    .el-input,
    .el-date-editor {
      width: 100%;
    }
    [class*="el-col-"] {
      float: right;
    }
  }
</style>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
    },
    data() {
      return {
        total: 0,
        per_page: 10,
        last_page: 0,
        current_page: 1,
        tableData: [],
        loading: false
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self
          .$fetch("admin/liveRooms", {
            page: self.current_page,
            per_page: self.per_page
          })
          .then(response => {
            if (response.status == 200) {
              self.tableData = response.data.data;
              self.total = response.data.meta.total;
              self.current_page = response.data.meta.current_page;
              self.last_page = response.data.meta.last_page;
            } else {
              console.log(response.message);
            }

            self.loading = false;
          })
          .catch(err => {
            console.log(err);
            self.loading = false;
          });
      },
      handleSizeChange(val) {
        self.per_page = val;
        self.getList();
      },
      handleCurrentChange(val) {
        self.current_page = val;
        self.getList();
      },
      toRefresh() {
        self.current_page = 1;
        self
          .$post("admin/liveRooms")
          .then(response => {
            if (response.status == 201) {
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            } else {
              console.log(response.data.message);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  };
</script>
